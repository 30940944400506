.custom-react-datepicker__wrapper {
  display: flex;
  font-display: row;
  justify-content: space-between;
  align-items: center;
  width: 272px;
}
.custom-react-datepicker__label-span {
  font-size: 15px;
  width: 100%;
  color: #2b2b2b;
}
.react-datepicker__input-container input {
  width: 200px;
  height: 19px;
  padding: 5px 10px;
  background: #f5f5f5;
  border: 1px solid white;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: #2b2b2b;
}
.react-datepicker-ignore-onclickoutside {
  border: 1px solid #e05185 !important;
  outline: none;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 5px;
}
.react-datepicker-wrapper {
  width: 115px;
}
.react-datepicker__header {
  height: 50px !important;
  padding: 0 !important;
  background-color: #fafafa !important;
  border: none !important;
  position: relative;
  text-align: center;
}
.custom-react-datepicker__select-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 280px;
  height: 50px;
}
.custom-react-datepicker__select-wrapper button {
  background-color: transparent;
  color: #605e83;
  margin: 0 10px;
  border: none;
}
.custom-react-datepicker__select-item {
  display: flex;
  font-display: row;
  justify-content: space-between;
  align-items: center;
  width: 80px;
}
.custom-react-datepicker__select-wrapper select {
  width: 60px;
  height: 30px;
  background-color: transparent;
  color: #605e83;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
}
.custom-react-datepicker__select-wrapper span {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 30px;
  margin-bottom: 5px;
  margin-left: 3px;
  font-weight: 500;
  font-size: 15px;
  color: #605e83;
}
.react-datepicker__month {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 25px !important;
}
.react-datepicker__month-container {
  background-color: #fafcff;
  border-radius: 5px;
  border: 1px solid white !important;
}
.react-datepicker__week {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin: 0.5px 0;
}
.react-datepicker__day-names {
  background-color: #fafcff;
}
.react-datepicker__day-name {
  font-size: medium;
  font-weight: 600;
  color: #3561eb;
  margin-left: 4px;
  margin-right: 4px;
}
.react-datepicker__day {
  width: 25px !important;
  font-size: 15px;
  line-height: 18px !important;
  color: #605e83;
  margin-left: 5px;
  margin-right: 5px;
}
.react-datepicker__day--outside-month {
  color:slategray !important;
}
.react-datepicker__day--selected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 7px !important;
  line-height: 14px;
}
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #3561eb !important;
  border-radius: 7px !important;
  color: white !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: #E4E4E4 !important;
  color: #2b2b2b !important;
}
.react-datepicker__day--today {
  background-color: #4ac5ff;
  border-radius: 7px;
  color: #2F2F35;
}
.react-datepicker__day--range-start {
  background-color: #3561eb !important;
  padding: 5px;
  border-radius: 5px !important;
  color: white !important;
}
.custom-react-datepicker__split-span {
  display: flex;
  font-display: row;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  width: 11px;
  margin: 0 10px;
  color: #2b2b2b;
}

.react-datepicker {
  z-index: 9999 !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  margin-top: 0;
  color: #000 !important;
  font-weight: bold;
  font-size: 0.944rem;
}
